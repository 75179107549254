@import '../node_modules/rfs/scss';

$navy: #343e47;
$wash: #bfcedd;
$yellow: #fdffa9;
$white: #ffffff;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Exo', sans-serif;
  color: $white;
  line-height: 1;
  background-color: $navy;
}

.home-landing {
  position: relative;
  display: flex;
  z-index: 99;
  max-width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  transition: 0.25s ease;
  background: url('../assets/game-background.jpg') no-repeat center center /
    cover;

  &--is-hidden {
    display: none;
  }

  &__container {
    display: grid;
    z-index: 105;
    gap: 1.875rem;
    margin: 2rem auto;
    text-align: center;
    grid-template-columns: repeat(12, minmax(0, 1fr));

    @media (min-width: 990px) {
      margin: 4rem auto;
      padding-left: 1.875rem;
      padding-right: 1.875rem;
    }
  }

  &__image {
    grid-column: 2 / 12;

    @media (min-width: 990px) {
      grid-column: span 6;
    }

    img {
      height: auto;
      max-width: 100%;
      object-fit: contain;
    }
  }

  &__intro {
    max-width: 100%;
    grid-column: 2 / 12;

    @media (min-width: 990px) {
      max-width: 520px;
      align-self: center;
      grid-column: 8 / 12;
      justify-self: center;
    }
  }

  &__title {
    display: inline-block;
    font-weight: 900;
    margin-bottom: 1rem;
    padding-bottom: 0.75rem;
    @include font-size(9rem);
    text-transform: uppercase;
    border-bottom: 4px solid $white;
  }

  &__subtitle {
    display: block;
    @include font-size(6rem);
  }

  &__description {
    margin-bottom: 2rem;
    line-height: 1.5rem;
    @include font-size(1.125rem);
  }
}

// Home Game
.home-game {
  position: relative;
  display: flex;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: url('../assets/game-background.jpg') no-repeat center center /
    cover;

  &--hidden {
    display: none;
  }

  &__instructions {
    display: none;

    @media (min-width: 768px) {
      display: block;
    }

    p {
      text-align: center;
      line-height: 1.5;
      font-size: 1.125rem;
      margin-bottom: 1rem;
    }

    &--mobile {
      display: block;

      @media (min-width: 768px) {
        display: none;
      }
    }
  }

  &__container {
    position: relative;
    z-index: 1;
    display: flex;
    margin: 0 auto;
    padding-left: 5vw;
    padding-right: 5vw;
    flex-direction: column;
  }

  &__navigation {
    display: flex;
    margin-top: 1.5rem;
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__pause {
    display: none;
    margin-top: 1rem;

    @media (min-width: 768px) {
      display: block;
      margin-top: 0;
    }
  }

  &__controls {
    display: flex;
    margin-top: 1.5rem;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    button {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 4px $wash;
      background-color: $wash;
      border: 1px solid $navy;

      &:last-child {
        margin-top: 1rem;
      }

      img {
        height: 1.5rem;
        width: 1.5rem;
      }
    }

    .btn-up,
    .btn-down {
      position: relative;
      height: 3rem;
      width: 3rem;
      border-radius: 50%;

      &:hover,
      &:focus,
      &:active {
        background-color: $wash;
      }

      &:active {
        top: 4px;
        box-shadow: none;
      }
    }
  }

  &__robot-arm {
    position: absolute;

    &--left {
      left: -32px;
      bottom: 20px;
      z-index: -1;

      @media (min-width: 990px) {
        bottom: 100px;
      }

      img {
        height: auto;
        width: 25vw;
        max-width: 445px;
      }
    }

    &--right {
      top: 50px;
      right: 0;
      z-index: -1;

      @media (min-width: 990px) {
        top: 200px;
      }

      img {
        height: auto;
        width: 21vw;
        max-width: 430px;
      }
    }
  }
}

#game-canvas {
  position: relative;
  z-index: 98;
  height: auto;
  max-width: 100%;
  border: 2px dashed $wash;
}

// Home Submit
.home-submit {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  opacity: 0;
  z-index: 2;
  height: auto;
  max-width: 100%;
  visibility: hidden;
  transition: 0.25s ease;
  background-color: $navy;

  @media (min-width: 990px) {
    margin: 3rem;
    box-shadow: 0px 4px 34px 10px rgba(0, 0, 0, 0.25);
  }

  &--is-visible {
    opacity: 1;
    visibility: visible;
  }

  &__container {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  &__close {
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;

    @media (min-width: 990px) {
      top: 24px;
      left: 24px;
    }

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }

  &__inner {
    position: relative;
    display: flex;
    z-index: 10;
    max-width: 700px;
    padding-left: 1rem;
    padding-right: 1rem;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    display: inline-block;
    font-weight: 900;
    margin-bottom: 1rem;
    @include font-size(7.5rem);
    text-transform: uppercase;
    border-bottom: 4px solid $white;
  }

  &__subtitle {
    display: block;
    @include font-size(9.25rem);
  }

  &__description {
    text-align: center;
    line-height: 1.5;
  }

  &__input {
    display: flex;
    width: 100%;
    margin-top: 2rem;
    flex-direction: column;

    @media (min-width: 990px) {
      width: auto;
      flex-direction: row;
    }

    input[type='text'] {
      border: none;
      width: 100%;
      outline: none;
      padding: 1rem;
      margin-right: 0;
      font-weight: 500;
      margin-bottom: 0.75rem;
      border-radius: 0.25rem;
      text-transform: uppercase;
      @include font-size(1.125rem);
      font-family: 'Exo', sans-serif;

      @media (min-width: 990px) {
        margin-bottom: 0;
        margin-right: 0.75rem;
      }
    }
  }
}

// Home Scoreboard
.home-scoreboard {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 2;
  margin: 0;
  height: auto;
  max-width: 100%;
  visibility: hidden;
  align-items: center;
  transition: 0.25s ease;
  flex-direction: column;
  justify-content: center;
  background-color: $navy;

  @media (min-width: 990px) {
    margin: 3rem;
    box-shadow: 0px 4px 34px 10px rgba(0, 0, 0, 0.25);
  }

  &--is-visible {
    opacity: 1;
    visibility: visible;
  }

  &__heading {
    font-weight: 700;
    text-align: center;
    margin-bottom: 1.5rem;
    @include font-size(2rem);
    text-transform: uppercase;
  }

  &__inner {
    width: 100%;
    margin-top: 59px;
    max-width: 600px;
    padding-left: 1rem;
    padding-right: 1rem;
    @include font-size(1.5rem);
  }

  &__entry {
    position: relative;
    display: flex;
    margin-top: 1rem;
    justify-content: space-between;

    &:first-of-type {
      margin-top: 0;
    }
  }

  &__close {
    position: absolute;
    cursor: pointer;
    z-index: 5;
    top: 0;
    left: 0;
    background-color: transparent;

    @media (min-width: 990px) {
      top: 24px;
      left: 24px;
    }

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
}

button {
  display: inline-block;
  cursor: pointer;
  color: $navy;
  border: none;
  outline: none;
  line-height: 1;
  font-weight: 800;
  text-align: center;
  user-select: none;
  white-space: nowrap;
  padding: 1rem 1.5rem;
  vertical-align: middle;
  border-radius: 0.25rem;
  background-color: $wash;
  text-transform: uppercase;
  @include font-size(1.125rem);
  font-family: 'Exo', sans-serif;
  transition: background-color 0.25s ease;

  &:hover,
  &:focus {
    background-color: $yellow;
  }
}
